import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updatePackageAmount, viewShoot } from "src/service/shoots.service";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";

import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";

export default function Shootsview() {
  const { shootId } = useParams();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchShootDetails = async () => {
    setIsLoading(true);
    const { data } = await viewShoot(shootId);
    setInputs(data?.data);
    setIsLoading(false);
  };
  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handleEditPackageAmount = () => {
    setIsEditing(true);
  };
  const handlePackageAmountChange = (event) => {
    setInputs({ ...inputs, packageAmount: event.target.value });
  };
  const packageUpdateHandler = async () => {
    const { data } = await updatePackageAmount(shootId, inputs);
    if (data?.code === 200) {
      setIsEditing(false);
      toast.success("Package Amount Updated Successfully");
    } else {
      toast.error("Data Not Updated");
    }
  };

  useEffect(() => {
    fetchShootDetails();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.shootNo?.split(" ")[0] || "Shoot"}'s detail | Camories{" "}
        </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        {/* {console.log(inputs, "gudum")} */}
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Shoot Details{console.log(inputs)}
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Card>
              <CardContent>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                      onClick={() => navigate(-1)}
                      style={{
                        backgroundColor: "white",
                        color: "#EFA8B0",
                        borderColor: "#EFA8B0",
                      }}
                    >
                      <ArrowBackIcon />
                      Back
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.shootNo || ""}
                        name="name"
                        label="Shoot No"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={
                          new Date(inputs?.shootDate).toLocaleString(
                            "en-US",
                            options
                          ) || ""
                        }
                        name="shootDate"
                        label="Shoot Date."
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.client?.name || ""}
                        name="name"
                        label="Client Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.client?.phoneNo || ""}
                        name="email"
                        label="Client Contact No"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.client?.email || ""}
                        name="email"
                        label="Client E-mail ID"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.hospital?.name || ""}
                        name="status"
                        label="Hospital Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.createdBy?.fullName || ""}
                        name="status"
                        label="Created By (user)"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.status || ""}
                        name="status"
                        label="Status"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        value={
                          inputs?.packageAmount !== undefined &&
                          inputs?.packageAmount !== null
                            ? inputs?.packageAmount
                            : ""
                        }
                        name="packageAmount"
                        label="Package Amount (₹)"
                        onChange={
                          isEditing ? handlePackageAmountChange : undefined
                        }
                        InputProps={{
                          readOnly: !isEditing,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={handleEditPackageAmount}
                        size="large"
                        color="inherit"
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    {isEditing && (
                      <Grid
                        xs={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={packageUpdateHandler}
                        >
                          Update Package Amount
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                  {inputs?.order && (
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/orders/${inputs?.order?._id}`)}
                    >
                      View Order
                    </Button>
                  )}
                </Box>
                {inputs.preSoftCopyURL && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 2,
                      marginTop: 4,
                      padding: 4,
                      borderRadius: 2,
                      border: "0.5px solid #74BAB6",
                    }}
                  >
                    <Typography variant="subtitle1">
                      Master Soft Copy Data
                    </Typography>
                    <Button
                      variant="outlined"
                      href={inputs?.preSoftCopyURL}
                      target="_blank"
                    >
                      Download
                    </Button>
                  </Box>
                )}
              </CardContent>
              {inputs?.eventTimestamps?.length > 0 && (
                <Card sx={{ margin: 3, border: "0.5px solid #EFA8B0" }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      marginBottom={3}
                      color="primary"
                      textAlign={"center"}
                    >
                      Event Timestamps
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                      }}
                    >
                      <Typography variant="subtitle1">Event</Typography>
                      <Typography variant="subtitle1">Timestamp</Typography>
                    </Box>
                    {inputs?.eventTimestamps?.map((item, index) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 2,
                            }}
                          >
                            <Typography>{item.event}</Typography>
                            <Typography variant="subtitle2">
                              {item.timestamp
                                ? new Date(item.timestamp).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long", // Displays full month name (e.g., December)
                                      day: "numeric",
                                      hour: "numeric", // Hour with AM/PM
                                      minute: "numeric", // Minutes
                                      hour12: true, // Displays the day (e.g., 21)
                                    }
                                  )
                                : "- NA -"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </CardContent>
                </Card>
              )}
            </Card>
          )}
        </CardContent>
      </Card>
    </>
  );
}
