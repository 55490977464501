import React, { useState } from "react";
import { Button, Modal, Fade } from "@mui/material";
import MapWithSearch from "./Google.Map.Container"; // Import the MapWithSearch component
import CloseIcon from "@mui/icons-material/Close";

const MapModal = ({
  open,
  handleClose,
  setAssignedLocation,
  selectedPlace,
  setSelectedPlace,
  module,
  showLocation,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              width: "90%",
              height: "75%",
              margin: "auto",
              marginTop: "50px",
              borderRadius: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {module === "attendance" ? (
                <div>
                  <h2 id="modal-title">View Location</h2>
                  <p id="modal-description"></p>
                </div>
              ) : (
                <div>
                  <h2 id="modal-title">Search Location</h2>
                  <p id="modal-description">
                    Use the map to search for a location:
                  </p>
                </div>
              )}
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </div>
            {module !== "attendance" ? (
              <MapWithSearch
                setAssignedLocation={setAssignedLocation}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
              />
            ) : (
              <MapWithSearch module={module} showLocation={showLocation} />
            )}
            {/* Render the MapWithSearch component */}
            {!selectedPlace && (
              <p
                style={{
                  position: "absolute",
                  right: "10%",
                  paddingTop: "9px",
                }}
              >
                {module !== "attendance" && "no place is selected"}
              </p>
            )}
            {selectedPlace && (
              <p
                style={{
                  position: "absolute",
                  right: "10%",
                  paddingTop: "9px",
                  fontWeight: "bolder",
                }}
              >
                {selectedPlace}
              </p>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default MapModal;
