import PropTypes from "prop-types";
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  TextField,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { saveAs } from "file-saver";

import React, { useContext, useEffect, useState } from "react";
import Filterdialog from "src/components/filter-dialog/Filter.Dialog";
import ReplayIcon from "@mui/icons-material/Replay";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import OrderContext from "src/context/Order/Order.Context.Provider";
import { toast } from "react-toastify";
import Slide from "@mui/material/Slide";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { getHospitals } from "src/service/hospital.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { exportReport } from "src/service/order.service";
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 321,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
OrderListToolbar.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
};

export default function OrderListToolbar({ search, onSearch, fetchData }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const getHospitalsList = async () => {
    const { data } = await getHospitals("", -1, 0, "");
    setHospitalList(data?.data?.items);
  };
  useEffect(() => {
    getHospitalsList();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { setStatus, setPage, setFetchedPages, setHospital, setDeliveryDate } =
    useContext(OrderContext);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const resetHandler = () => {
    setPage(0);
    setFetchedPages(1);
    setStatus("");
    setHospital("");
    setDeliveryDate("");

    fetchData("", 0, "");
    toast("Reset applied filter", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "warning",
    });
  };
  const handleExportReport = async () => {
    if (!hospitalId) {
      toast("Please select hospital", {
        position: "top-right",
        autoClose: 3579,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "error",
      });
      return;
    }
    if (!startDate || !endDate) {
      toast("Please select both the dates", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "error",
      });
      return;
    }
    setSummaryLoader(true);
    const { data } = await exportReport(
      startDate.valueOf(),
      endDate.valueOf(),
      hospitalId?._id
    );
    if (data?.size > 0) {
      console.log("if statement block");
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });

      setSummaryLoader(false);
      saveAs(data, `Camories Order Report_${formattedDate}.xlsx`);
    }
  };
  return (
    <StyledRoot>
      <StyledSearch
        value={search}
        onChange={onSearch}
        placeholder={"Search by Order"}
        startAdornment={
          <Tooltip title="search" arrow>
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          </Tooltip>
        }
      />
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClickOpen}>
          <FilePresentOutlinedIcon />
          Export
        </Button>
        <Tooltip title="Reset Filter" arrow>
          <IconButton>
            <ReplayIcon onClick={resetHandler} />
          </IconButton>
        </Tooltip>
        <Filterdialog
          open={dialogOpen}
          onClose={handleDialogClose}
          module="order"
        />

        <Box onClick={handleDialogOpen}>
          <Tooltip title="Filter list" arrow>
            <IconButton>
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {/* export button dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Export Modal"}</DialogTitle>
        <Alert severity="info">
          Select Hospital Name and Date Range to Export Excel File
        </Alert>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} marginBottom={2}>
              <Autocomplete
                name="hospital"
                onChange={(e, newValue) => setHospitalId(newValue)}
                fullWidth
                value={hospitalId || ""}
                options={hospitalList || []}
                renderInput={(params) => (
                  <TextField {...params} label="Hospitals" required />
                )}
                getOptionLabel={(options) =>
                  options?._id ? ` ${options?.name}` : ""
                }
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  value === "" ||
                  option?._id === value?._id
                }
                getOptionSelected={(option, value) =>
                  option?._id === value?._id
                }
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  value={startDate}
                  name="startDate"
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={(newStartDate) => {
                    if (newStartDate)
                      return setStartDate(dayjs(newStartDate).startOf("day"));
                    return setStartDate(null);
                  }}
                  //   sx={{ width: "100% !important" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  value={endDate}
                  name="startDate"
                  format="DD/MM/YYYY"
                  disableFuture
                  onChange={(newEndDate) => {
                    if (newEndDate)
                      return setEndDate(dayjs(newEndDate).endOf("day"));
                    return setEndDate(null);
                  }}
                  disabled={!startDate}
                  minDate={startDate ? dayjs(startDate) : null}
                  //   sx={{ width: "100% !important" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          {summaryLoader ? (
            <CircularProgress size={18} />
          ) : (
            <Button onClick={handleExportReport} variant="contained">
              Download
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
