import React from "react";

import PropTypes from "prop-types";

import { DialogContent as MuiDialogContent, Typography } from "@mui/material";

import UserFilterDialog from "./User.Filter.Dialog.Content";
import HospitalFilterDialog from "./Hoapital.Filter.Dialog.Content";
import AttendanceFilterDialogContent from "./Attendance.Filter.Dialog.Content";
import TicketFilterDialogContent from "./Ticket.Filter.Dialog.Content";
import ShootFilterDialogContent from "./Shoot.Filter.Dialog.Content";
import OrderFilterDialogContent from "./Order.Filter.Dialog.Content";
import PackageAnalyticsFilterDialogContent from "./Package.Analytics.Filter.Dialog.Content";
import AmountAnalyticsFilterDialogContent from "./Amount.Analytics.Filter.Dialog.Content";
import LeavesFilterDialogContent from "./Leaves.Filter.Dialog.Content";

const DialogContent = ({ onClose, module }) => {
  const ShowDialog = () => {
    switch (module) {
      case "user":
        return <UserFilterDialog onClose={onClose} />;
      case "hospital":
        return <HospitalFilterDialog onClose={onClose} />;
      case "attendance":
        return <AttendanceFilterDialogContent onClose={onClose} />;
      case "ticket":
        return <TicketFilterDialogContent onClose={onClose} />;
      case "shoot":
        return <ShootFilterDialogContent onClose={onClose} />;
      case "order":
        return <OrderFilterDialogContent onClose={onClose} />;
      case "Amount":
        return <AmountAnalyticsFilterDialogContent onClose={onClose} />;
      case "Package":
        return <PackageAnalyticsFilterDialogContent onClose={onClose} />;
      case "leaves":
        return <LeavesFilterDialogContent onClose={onClose} />;

      default:
        return null;
    }
  };

  return (
    <MuiDialogContent
      sx={{
        minWidth: "500px",
        minHeight: "350px",
        position: "relative",
      }}
    >
      <Typography variant="h4" marginBottom={3} color="#74BAB6">
        Filter By
      </Typography>
      <ShowDialog />
    </MuiDialogContent>
  );
};

DialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
};

export default DialogContent;
