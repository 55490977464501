import {
  Box,
  Container,
  Divider,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";

export default function PrivacyPolicy() {
  return (
    <Box sx={{ paddingBottom: 8 }}>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        <Box sx={{ textAlign: "center", marginBottom: 5 }}>
          <Typography variant="h3" color="primary" gutterBottom>
            Privacy Policy of Camories Studio
          </Typography>
          <Typography>
            This Privacy Policy describes the policies and procedures of
            Camories Studio on the collection, use, and disclosure of your
            information when you use our services. It also explains your privacy
            rights and how the law protects you.
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            By using our services, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </Typography>
        </Box>
        <Divider sx={{ marginY: 4 }} />

        <Box>
          <Typography variant="h4" gutterBottom>
            Interpretation and Definitions
          </Typography>
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Interpretation
          </Typography>
          <Typography>
            Words with capitalized initial letters have specific meanings as
            defined below.
          </Typography>
          <Typography variant="h5" sx={{ marginTop: 3 }}>
            Definitions
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Company: Refers to Camories Studio, located in Bangalore, India." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Personal Data: Any information related to an identified or identifiable individual." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Service: Refers to photography and related services provided by Camories Studio." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="You: The individual using our service." />
            </ListItem>
          </List>
        </Box>
        <Divider sx={{ marginY: 4 }} />

        <Paper
          elevation={3}
          sx={{
            padding: 4,
            marginY: 4,
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Collecting and Using Your Personal Data
          </Typography>
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            Types of Data Collected
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Personal Data: Includes your name, email address, phone number, and delivery address (if required)." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Usage Data: Information automatically collected when you access our website, such as browser type, IP address, and time spent on our site." />
            </ListItem>
          </List>
          <Typography variant="h5" sx={{ marginTop: 3 }}>
            How We Use Your Data
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Deliver photographs and related services." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Communicate with you regarding your orders." />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
              </ListItemIcon>
              <ListItemText primary="Ensure legal and regulatory compliance." />
            </ListItem>
          </List>
        </Paper>

        <Divider sx={{ marginY: 4 }} />

        <Typography variant="h5" sx={{ marginTop: 3 }}>
          Sharing Your Personal Data
        </Typography>
        <Typography>
          We only share your personal data in the following situations:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
              <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="To fulfill your order, such as sharing your email or delivery details with necessary service providers." />
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
              <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="To comply with legal obligations or respond to lawful requests." />
          </ListItem>
        </List>
        <Typography>
          We do not use your data for marketing purposes or share it with third
          parties for any other reason.
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <Typography variant="h5" sx={{ marginTop: 3 }}>
          Retention of Your Personal Data
        </Typography>
        <Typography>
          We retain your personal data only as long as necessary to deliver your
          photos or comply with legal obligations. Once your photos are
          delivered, we securely delete all associated data unless required
          otherwise by law.
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <Typography variant="h5" sx={{ marginTop: 3 }}>
          Your Rights
        </Typography>
        <Typography>You have the right to:</Typography>
        <List>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
              <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Access, update, or delete the personal data we hold about you." />
          </ListItem>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
              <CircleIcon fontSize="small" sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Restrict or object to how we process your personal data." />
          </ListItem>
        </List>
        <Typography>
          To exercise your rights, contact us at{" "}
          <strong>hello@camories.in</strong>.
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <Typography variant="h5" sx={{ marginTop: 3 }}>
          Security of Your Personal Data
        </Typography>
        <Typography>
          We prioritize the security of your personal data and take appropriate
          measures to protect it. However, no method of transmission or storage
          is 100% secure.
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <Typography variant="h5" sx={{ marginTop: 3 }}>
          Changes to This Privacy Policy
        </Typography>
        <Typography>
          We may update this Privacy Policy periodically. Any updates will be
          posted on this page with the revised “Last updated” date.
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <Box>
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Box
              sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
            >
              <EmailIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography>Email: hello@camories.in</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography>Address: Bangalore, India</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
