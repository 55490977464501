import { getAttendanceApi } from "./api.request";

const ATTENDANCE_PATH = "apis/attendance/records";

export const getAttendances = async (
  empCode,
  formDate,
  toDate,
  limit,
  offset
) =>
  getAttendanceApi(
    `${ATTENDANCE_PATH}`,
    empCode,
    formDate,
    toDate,
    limit,
    offset
  );
