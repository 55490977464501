import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { Box } from "@mui/material";
import { useContext, useState } from "react";
import Filterdialog from "src/components/filter-dialog/Filter.Dialog";
import ReplayIcon from "@mui/icons-material/Replay";
import UserListContext from "src/context/User/User.Context.Provider";
import AttendanceListContext from "src/context/Attendance/Attendance.Context.Provider";
import { toast } from "react-toastify";
import LeavesContext from "src/context/Leaves/Leaves.Context.Provider";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 321,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function LeavesListToolbar({ fetchData }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    setEmpCode,
    setFromDate,
    setToDate,
    setPage,
    setFetchedPages,
    setStatus,
  } = useContext(LeavesContext);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const resetFetchData = () => {
    setPage(0);
    setFetchedPages(1);
    setEmpCode("");
    setFromDate("");
    setToDate("");
    setStatus("");
    toast("Reset applied filter", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "warning",
    });
  };
  return (
    <StyledRoot>
      <Box
        sx={{ display: "flex", gap: 2, justifyContent: "flex-end", ml: "auto" }}
      >
        <Tooltip title="Reset Filter" arrow>
          <IconButton>
            <ReplayIcon onClick={resetFetchData} />
          </IconButton>
        </Tooltip>
        <Filterdialog
          open={dialogOpen}
          onClose={handleDialogClose}
          module="leaves"
        />

        <Box onClick={handleDialogOpen}>
          <Tooltip title="Filter list" arrow>
            <IconButton>
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </StyledRoot>
  );
}
