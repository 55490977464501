import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SalesAnalyticsContext from "src/context/Analytics/Sales.Analytics.Context.Provider";
import { getUsers } from "src/service/user.service";
import { getHospitals } from "src/service/hospital.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PackageAnalyticsFilterDialogContent({ onClose }) {
  const {
    status,
    setStatus,
    employeeName,
    setEmployeeName,
    hospitalName,
    setHospitalName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useContext(SalesAnalyticsContext);
  const [employeeList, setEmployeeList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [todateFilter, setToDateFilter] = useState(null);

  const fetchEmployee = async () => {
    const { data } = await getUsers("", "", -1, 0, "");
    const temp = data?.data?.items;
    setEmployeeList(temp);
  };
  const fetchHospital = async () => {
    const { data } = await getHospitals("", -1, 0, "");
    const temp = data?.data?.items;
    setHospitalList(temp);
  };

  const resetHandler = () => {
    setEmployeeName("");
    setHospitalName("");
    setStartDate(dayjs().startOf("month").startOf("day").valueOf());
    setEndDate(dayjs().endOf("day").valueOf());
    setStatus("");
    onClose();
    toast("Reset applied filter", {
      position: "top-right",
      autoClose: 3579,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "warning",
    });
    return;
  };

  const handleApply = () => {
    let append = "";
    if (employeeName) {
      append += `&empCode=${employeeName?.empCode}`;
    }

    if (hospitalName) {
      append += `&hospital=${hospitalName?._id}`;
    }
    // if (fromDateFilter === null) {
    //     toast('Please select from Date!', {
    //         position: "top-right",
    //         autoClose: 3579,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //         type:'error',

    //         });
    //     return;
    // }if(todateFilter === null){
    //     toast('Please select to Date!', {
    //         position: "top-right",
    //         autoClose: 3579,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //         type:'error',

    //         });
    //     return;
    // }
    if (fromDateFilter !== null || todateFilter !== null) {
      // Ensure that fromDateFilter and todateFilter are Dayjs objects
      const minFromDate =
        todateFilter !== null
          ? todateFilter.subtract(31, "day").endOf("day")
          : dayjs().subtract(31, "day").endOf("day");
      // const maxToDate = fromDateFilter? fromDateFilter.add(30, 'day').endOf('day'):fromDate
      console.log(minFromDate, "minfrom date");
      console.log(fromDateFilter, "value");

      if (fromDateFilter?.isBefore(minFromDate)) {
        toast("There can only be maximum 31 days of gap ", {
          position: "top-right",
          autoClose: 3579,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
        return;
      } else {
        const timestamp = fromDateFilter
          ? dayjs(fromDateFilter).startOf("day").valueOf()
          : startDate;
        const time = todateFilter
          ? dayjs(todateFilter).endOf("day").valueOf()
          : endDate;

        setStartDate(timestamp);

        setEndDate(time);
        console.log(dayjs(timestamp).format("DD/MM/YYYY"), "start date");
        console.log(dayjs(time).format("DD/MM/YYYY"), "end date");
      }
    }
    // else {
    //   console.log("else block");
    //   setStartDate(startDate);
    //   setEndDate(endDate);
    // }
    setStatus(append);
    setTimeout(() => {
      toast("Filter applied successfully", {
        position: "top-right",
        autoClose: 3579,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "success",
      });
    }, 100);
    onClose();
  };
  useEffect(() => {
    fetchEmployee();
    fetchHospital();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            name="employee"
            onChange={(e, newValue) => setEmployeeName(newValue)}
            fullWidth
            value={employeeName || ""}
            options={employeeList || []}
            renderInput={(params) => (
              <TextField {...params} label="Select User" />
            )}
            getOptionLabel={(options) =>
              options?.empCode ? `${options?.fullName} ` : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            name="ccCode"
            onChange={(e, newValue) => setHospitalName(newValue)}
            fullWidth
            value={hospitalName || ""}
            options={hospitalList || []}
            renderInput={(params) => (
              <TextField {...params} label="Select Hospital" />
            )}
            getOptionLabel={(options) =>
              options?._id ? `${options?.name}` : ""
            }
          />
        </Grid>

        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={dayjs(startDate)}
              name="startDate"
              format="DD/MM/YYYY"
              onChange={(newStartDate) => {
                if (newStartDate)
                  //     return setStartDate(dayjs(newStartDate).startOf("day"));
                  //   return setStartDate(null);
                  setFromDateFilter(newStartDate);
                //   console.log(fromDate, 'fromdate times');
                // 12:00 a.m.
              }}
              sx={{ width: "100% !important" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              value={dayjs(endDate)}
              name="startDate"
              format="DD/MM/YYYY"
              onChange={(newEndDate) => {
                if (newEndDate)
                  //     return setEndDate(dayjs(newEndDate).endOf("day"));
                  //   return setEndDate(null);
                  setToDateFilter(newEndDate);
              }}
              sx={{ width: "100% !important" }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box>
        <Button sx={{ mt: 2 }} variant="outlined" onClick={resetHandler}>
          Reset Filter
        </Button>
        <Box
          //  position="absolute" right="25px" bottom="25px"
          sx={{ mt: 2, float: "right" }}
        >
          <Button onClick={onClose} color="primary" sx={{ marginRight: "4px" }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </>
  );
}
