import { createContext, useState } from "react";
import { getLeaves } from "src/service/leaves.service";

const LeavesContext = createContext();
export const LeavesContextProvider = ({ children }) => {
  const [leavesData, setLeavesData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(0);
  const [fetchedPages, setFetchedPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [empCode, setEmpCode] = useState("");
  const fetchData = async (
    filterName,
    FromDate,
    ToDate,
    offset = 0,
    statusFilter
  ) => {
    setIsLoading(true);
    const { data } = await getLeaves(
      filterName,
      FromDate,
      ToDate,
      rowsPerPage,
      offset,
      statusFilter
    );
    setIsLoading(false);
    if (data?.data?.pagination?.offset === "0")
      setLeavesData(data?.data?.items);
    else {
      setLeavesData((preValue) => {
        console.log("trying to get leaves array", [
          ...preValue,
          ...data?.data?.items,
        ]);
        return [...preValue, ...data?.data?.items];
      });
    }
    console.log(data?.data?.items);
    setPagination(data?.data?.pagination);
  };

  return (
    <LeavesContext.Provider
      value={{
        leavesData,
        setLeavesData,
        fetchData,
        pagination,
        setPagination,
        rowsPerPage,
        setRowsPerPage,
        fetchedPages,
        page,
        setPage,
        setFetchedPages,
        status,
        setStatus,
        filter,
        setFilter,
        search,
        setSearch,
        isLoading,
        setIsLoading,
        toDate,
        setToDate,
        fromDate,
        setFromDate,
        setEmpCode,
        empCode,
      }}
    >
      {children}
    </LeavesContext.Provider>
  );
};
export default LeavesContext;
