/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet-async";
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { CURRENT_USER } from "src/utils/constants";
import AttendanceListContext from "src/context/Attendance/Attendance.Context.Provider";

import { UserListHead } from "src/sections/@dashboard/user";
import AttendanceListToolbar from "src/sections/@dashboard/attendance/AttendanceListToolbar";
import MapModal from "src/components/map/Map.Modal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#74BAB6" />
            <stop offset="100%" stopColor="#EFA8B0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </React.Fragment>
  );
}

export default function Attendance() {
  const [open, setOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const TABLE_HEAD = [
    { id: "empCode", label: "Employee Code", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "checkInTime", label: "Check In Time", alignRight: false },
    { id: "checkOutTime", label: "Check Out Time", alignRight: false },
    { id: "hourwsOfWork", label: "Hours Of Work", alignRight: false },
    { id: "location", label: "Location", alignRight: false },
  ];

  const {
    attendanceData,
    fetchData,
    pagination,
    rowsPerPage,
    fetchedPages,
    page,
    setPage,
    setFetchedPages,
    isLoading,
    empCode,
    fromDate,
    toDate,
  } = useContext(AttendanceListContext);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    setPage(newPage);
    if (newPage > fetchedPages - 1) {
      setFetchedPages((value) => value + 1);
    }
    console.log(fetchedPages, "fetchedPages");
  };

  console.log(attendanceData, "attendanceData");

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - attendanceData?.length)
      : 0;

  const filteredUsers = applySortFilter(
    attendanceData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredUsers?.length;
  const viewLocation = (row) => {
    handleOpen();
    setSelectedPlace(row.location.coordinates);
  };

  useEffect(() => {
    let timer;
    console.count("test surveyList useEffect");

    const fetchDataWithDebounce = () => {
      console.log(empCode, fromDate, toDate, page * rowsPerPage, "test");
      fetchData(empCode, fromDate, toDate, page * rowsPerPage);
    };

    // const debounce = () => {
    //   timer = setTimeout(fetchDataWithDebounce, 800);
    // };

    const noDebounce = () => {
      if (fetchedPages === 1 || page >= fetchedPages - 1) {
        fetchDataWithDebounce();
      }
    };

    noDebounce();
    return () => {
      clearTimeout(timer);
    };
  }, [empCode, fetchedPages, fromDate, toDate]);

  return (
    <>
      <Helmet>
        <title> Attendance | Camories </title>
      </Helmet>
      <Container maxWidth={false}>
        <Card>
          <AttendanceListToolbar fetchData={fetchData} />

          <Scrollbar>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                }}
              >
                <GradientCircularProgress />
              </Box>
            ) : attendanceData?.length > 0 || isNotFound ? (
              <TableContainer sx={{ minWidth: 800, overflow: "hidden" }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {filteredUsers?.map((row) => {
                      if (!row?.user) {
                        <GradientCircularProgress />;
                      }
                      const { _id, checkInTime, checkOutTime } = row;

                      const checkInTimeDate = new Date(checkInTime) || "";
                      const checkOutTimeDate = checkOutTime
                        ? new Date(checkOutTime)
                        : new Date();
                      const timeDifferenceInMilliseconds =
                        checkOutTimeDate.getTime() - checkInTimeDate.getTime();

                      //total working hours----
                      const hours = Math.floor(
                        timeDifferenceInMilliseconds / (1000 * 60 * 60)
                      );
                      const minutes = Math.floor(
                        (timeDifferenceInMilliseconds % (1000 * 60 * 60)) /
                          (1000 * 60)
                      );
                      const empCodeData = row?.user?.empCode || "";
                      const name = row?.user?.fullName || "";

                      const options = {
                        timeZone: "Asia/Kolkata",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      };
                      // console.log(filteredUsers, "bal");

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              // sx={{ cursor: "pointer" }}
                            >
                              {empCodeData || ""}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                // sx={{ cursor: "pointer" }}
                              >
                                {name || ""}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {!checkInTime
                              ? "-"
                              : checkInTimeDate.toLocaleString(
                                  "en-US",
                                  options
                                )}
                          </TableCell>
                          <TableCell align="left">
                            {!checkOutTime
                              ? "-"
                              : checkOutTimeDate.toLocaleString(
                                  "en-US",
                                  options
                                )}
                          </TableCell>

                          <TableCell align="left">
                            {`${hours}Hours, ${minutes}minutes`}
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() => viewLocation(row)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow sx={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Data not available.</Typography>
              </Box>
            )}
          </Scrollbar>
          {!isLoading && filteredUsers?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <MapModal
        open={open}
        handleClose={handleClose}
        showLocation={selectedPlace}
        module="attendance"
      />

      {/* <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={() => handleEdit(selectedHospitalId)}>Edit</MenuItem>

      <MenuItem sx={{ color: 'error.main' }}>
        <>
          <Grid
            item
            xs={10}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'black',
            }}
            onClick={() => handleEnable(selectedHospitalId)}
          >
            {enable ? 'Disable' : 'Enable'}
          </Grid>
        </>
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDialogDelete}>
        Delete
      </MenuItem>
    </Popover>
    <Dialog open={isDialogOpen} onClose={handleCloseDialogDelete} TransitionComponent={Transition}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Hospital?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogDelete} variant="outlined" style={{ backgroundColor: 'white', color: '#EFA8B0', borderColor:'#EFA8B0' }}>
          Cancel
        </Button>
        <Button variant="outlined" style={{ backgroundColor: 'white', color: '#74BAB6', borderColor:'#74BAB6' }} onClick={() => handleDelete(selectedHospitalId)}>
          Delete
        </Button>
      </DialogActions>
    </Dialog> */}
    </>
  );
}
