import { getDetailsApi, getLeavesApi, updateApi } from "./api.request";

const LEAVES_PATH = "/apis/attendance/leaves";

export const getLeaves = async (
  empCode,
  fromDate,
  toDate,
  limit,
  offset,
  status
) =>
  getLeavesApi(
    `${LEAVES_PATH}`,
    empCode,
    fromDate,
    toDate,
    limit,
    offset,
    status
  );
export const viewLeave = async (leavesId) =>
  getDetailsApi(`${LEAVES_PATH}/${leavesId}`);
export const updateLeave = async (body) =>
  updateApi(`${LEAVES_PATH}/status`, body);
