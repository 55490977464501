import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { Link, TextField } from "@mui/material";
import Iconify from "../../../components/Iconify";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CURRENT_USER,
  ANOTHER_DEVICE,
  KEY_AUTH_TOKEN,
} from "../../../utils/constants";
import InputAdornment from "@mui/material/InputAdornment";
import { authSignIn } from "src/service/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [inputField, setInputField] = useState({});
  const [isRememberMe, setIsRememberMe] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputField((preValue) => ({ ...preValue, [name]: value }));
  };

  const handleSignInClick = async () => {
    console.log(inputField);
    const response = await authSignIn(inputField);
    console.log(response, "response");
    if (response?.isSuccess === true) {
      const { token, user } = response?.data?.data;
      if (user.role === "PHOTOGRAPHER") {
        toast("Access Denied! Please contact ADMIN.", {
          position: "top-center",
          autoClose: 3579,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
        return;
      }

      if (isRememberMe) {
        localStorage.setItem(KEY_AUTH_TOKEN, token);
        localStorage.setItem(CURRENT_USER, JSON.stringify(user));
      }
      sessionStorage.setItem(KEY_AUTH_TOKEN, token);
      sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
      if (user.role === "ADMIN" || user.role === "EDITOR") {
        navigate("/dashboard", { replace: true });
      }

      toast("Logged in", {
        position: "top-center",
        autoClose: 3579,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "success",
      });
    } else {
      toast("Wrong credentials!", {
        position: "top-center",
        autoClose: 3579,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "error",
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSignInClick();
    }
  };
  useEffect(() => {
    const LogInData =
      JSON.parse(localStorage.getItem(CURRENT_USER)) &&
      JSON.parse(localStorage.getItem(KEY_AUTH_TOKEN));

    setIsRememberMe(LogInData ? true : false);
  }, []);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="empCode"
          label="Employee Code"
          value={inputField.email}
          onChange={handleOnChange}
        />

        <TextField
          name="pass"
          label="Password"
          value={inputField.password}
          onChange={handleOnChange}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyDown}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 3 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            name="remember"
            value={isRememberMe}
            onChange={() => setIsRememberMe(!isRememberMe)}
            style={{
              backgroundColor: "white",
              color: "#74BAB6",
              borderColor: "#74BAB6",
            }}
          />
          Remember Me
        </Box>
        {/* <Link variant="subtitle2" underline="hover">
        Forgot password?
      </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleSignInClick}
        style={{
          backgroundColor: "#74BAB6",
          color: "white",
          borderColor: "#74BAB6",
        }}
      >
        Login
      </LoadingButton>
      {/* <ToastContainer/> */}
    </>
  );
}
