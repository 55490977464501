import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import { useContext, useEffect, useState } from "react";

import {
  getOverallAnalytics,
  getSalesAmount,
  getSalesPackage,
} from "../service/analytics.service";
import SalesAnalyticsContext from "src/context/Analytics/Sales.Analytics.Context.Provider";
import { CURRENT_USER } from "src/utils/constants";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const logInData =
    JSON.parse(localStorage.getItem(CURRENT_USER)) ||
    JSON.parse(sessionStorage.getItem(CURRENT_USER));

  // const theme = useTheme();
  // const [pagination, setPagination] = useState(null);
  // const [page, setPage] = useState(0);
  // const [fetchedPages, setFetchedPages] = useState(1);
  // // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [search, setSearch] = useState('');
  // const [status, setStatus] = useState('');
  // const [filter, setFilter] = useState('');

  // const [salesAmountData, setSalesAmountData] = useState([]);
  // const [salesAmountDate, setSalesAmountDate] = useState([]);
  // const [salesPackageData, setSalesPackageData] = useState([]);
  //   const [salesPackageDate, setSalesPackageDate] = useState([]);
  const {
    salesAmountData,
    salesAmountDate,
    salesPackageData,
    salesPackageDate,
    fetchAmountData,
    fetchPackageData,
    filter,

    status,

    fromDate,
    toDate,
    startDate,
    endDate,
  } = useContext(SalesAnalyticsContext);
  const [analyticsDetails, setAnalyticsDetails] = useState({});

  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const getOverallAnalyticsData = async () => {
    const { data } = await getOverallAnalytics();
    setAnalyticsDetails(data?.data);
    console.log("getting the dashboard data", analyticsDetails);
  };

  // const fetchAmountData = async(offset=0, statusFilter)=>{
  //   const {data} = await getSalesAmount(rowsPerPage,offset,statusFilter);
  //   console.log(data, 'graph');
  //   setSalesAmountData(data?.data?.map((item)=>item?.amount));
  //   setSalesAmountDate(data?.data?.map((item)=>new Date(item?.shootDate).toLocaleString("en-US", options)));
  // }
  // const fetchPackageData = async(offset=0, statusFilter)=>{
  //   const {data} = await getSalesPackage(rowsPerPage,offset,statusFilter);
  //   console.log(data, 'graph');
  //   setSalesPackageData(data?.data?.map((item)=>item?.count));
  //     setSalesPackageDate(data?.data?.map((item)=>item?.packageAmount));;
  // }
  // console.log(salesAmountDate, "graph");
  useEffect(() => {
    getOverallAnalyticsData();
    logInData?.role !== "EDITOR" && fetchAmountData(fromDate, toDate, filter);
    logInData?.role !== "EDITOR" &&
      fetchPackageData(startDate, endDate, status);
  }, [filter, status, fromDate, toDate, startDate, endDate]);

  return (
    <Page title="Dashboard">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back {logInData?.fullName}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Pending Soft Copies"
              total={analyticsDetails?.pendingSoftCopies}
              icon={"ant-design:android-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Pending Frames To Be Sent"
              total={analyticsDetails?.pendingFrames}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Pending Shoots"
              total={analyticsDetails?.pendingShoots}
              color="warning"
              icon={"ant-design:windows-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Tickets Pending"
              total={analyticsDetails?.ticketPending}
              color="error"
              icon={"ant-design:bug-filled"}
            />
          </Grid>
          {logInData?.role === "ADMIN" && (
            <Grid item xs={12} md={6} lg={12}>
              <AppWebsiteVisits
                title="Sales Amount Analytics"
                // subheader="(+43%) than last year"
                moduleName="Amount"
                chartLabels={salesAmountDate}
                chartData={[
                  {
                    name: "Amount (₹)",
                    type: "column",
                    // type: 'line',
                    fill: "solid",
                    data: salesAmountData,
                  },
                  // {
                  //   name: "Amount",
                  //   type: "area",
                  //   fill: "gradient",
                  //   data: salesAmountData,
                  // },
                  // {
                  //   name: 'Team C',
                  //   type: 'column',
                  //   // type: 'line',
                  //   fill: 'solid',
                  //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  // },
                ]}
              />
            </Grid>
          )}
          {logInData?.role === "ADMIN" && (
            <Grid item xs={12} md={6} lg={12}>
              <AppWebsiteVisits
                title="Sales Package Analytics"
                moduleName="Package"
                // subheader="(+43%) than last year"
                chartLabels={salesPackageDate}
                chartData={[
                  {
                    name: "Count",
                    type: "column",
                    // type: 'line',
                    fill: "solid",
                    data: salesPackageData,
                  },
                ]}
              />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
